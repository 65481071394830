import React from "react"

import Seo from "../components/Seo"
import MainContent from "../components/MainContent"
import Header from '../components/Header';
import Footer from '../components/Footer';

const NotFoundPage = ({ location }) => (
  <>
    <Header />
    <MainContent pathname={location.pathname}>
      <Seo title="404: Not found" canonical={location.pathname} />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </MainContent>
    <Footer />
  </>
)

export default NotFoundPage
